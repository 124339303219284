<template>
  <div class="container">
    <div class="top">
      <div class="top_title">
        <div class="top_title_small"> {{info.title_1}} </div>
        <div class="top_title_big"> {{info.title_2}} </div>
      </div>
      <div class="top_hint">
        <div class="top_hint_small"> {{info.hint_1}} </div>
        <div class="top_hint_small marginLeft"> {{info.hint_2}} </div>
      </div>
      <div class="top_btn" @click.stop="toJoin">申请加入</div>
    </div>
    <div class="mid">
      <div class="mid_hint">
        <div class="mid_hint_title">{{midInfo.hint_1}}</div>
        <div class="mid_hint_title">{{midInfo.hint_2}}</div>
      </div>
      <div class="mid_box">
        <div class="mid_box_title"> {{midItem_1.title}} </div>
        <div class="box_items">
          <div class="mid_box_item" v-for="item in midItem_1.list" :key="item">
            <img class="item_pic" :src="item.img">
            <div class="item_title"> {{item.title}} </div>
            <div class="item_text"> {{item.text}} </div>
          </div>
        </div>
      </div>
      <div class="mid_box">
        <div class="mid_box_title"> {{midItem_2.title}} </div>
        <div class="box_items box_items_2">
          <div class="mid_box_item" v-for="item in midItem_2.list" :key="item">
            <img class="item_pic" :src="item.img">
            <div class="item_title"> {{item.title}} </div>
            <div class="item_text"> {{item.text}} </div>
          </div>
        </div>
      </div>
      <div class="mid_box">
        <div class="mid_box_title"> {{midItem_3.title}} </div>
        <div class="box_items box_items_2">
          <div class="mid_box_item mid_box_item_3" v-for="item in midItem_3.list" :key="item">
            <div class="item_text"> {{item.text}} </div>
            <div class="num"> {{item.num}} </div>
          </div>
        </div>
      </div>
      <!--加入我们-->
      <div class="mid_box">
        <div class="mid_box_title"> {{joinInfo.title}} </div>
        <div class="join_box">
          <div class="join_box_t"> {{joinInfo.hint}} </div>
          <div class="btn" @click.stop="toJoin"> 申请加入  </div>
        </div>
      </div>

    </div>
    <!--展示信息-->
    <div class="mask" v-if="showInfo">
      <div class="mask_info">
        <div class="mask_info_title">联系我们</div>
        <div class="mask_info_item" v-for="item in maskList" :key="item">
          <div class="left">{{item.info_1}}：</div>
          <div class="right">{{item.info_2}}</div>
        </div>
        <div class="mask_btn">
          <div class="btn" @click.stop="showInfo = false">关闭</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "partner",
    data(){
      return {
        info: {
          title_1: '城市合伙人',
          title_2: '招募计划',
          hint_1: '合作共享',
          hint_2: ' 开放共赢',
        },
        midInfo: {
          hint_1: '在疫情爆发的大背景下，互联网+医疗行业的发展迎来风口。',
          hint_2: '玉螺互联网医疗作为互联网医院建设与运营服务商，致力于推动互联网+医疗行业高质量发展，整合医疗产业链资源，打造线上线下一体化医疗生态圈。为了充分发挥各方资源优势，快速开拓全国各地市场，推动玉螺互联网医疗业务布局，实现互利共赢目标，玉螺互联网医疗现面向全国开展城市合伙人招募计划。',
        },
        midItem_1: {
          title: '平台优势',
          list: [
            {
              img: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/compony_1_1.png',
              title: '三甲医院成功案例',
              text: '玉螺互联网医疗现阶段已具备可复制落地的技术解决方案',
            },
            {
              img: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/compony_1_2.png',
              title: '管理团队经验丰富',
              text: '拥有专业的技术人员及核心管理团队，实践经验丰富',
            },
          ]
        },
        midItem_2: {
          title: '三大资源',
          list: [
            {
              img: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/compony_1_3.png',
              title: '资源共享',
              text: '共享玉螺互联网医疗相关互联网医疗渠道资源、项目实施方案等资源。',
            },
            {
              img: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/compony_1_4.png',
              title: '品牌授权',
              text: '玉螺互联网医疗品牌授权，快速提升合伙人市场影响力。',
            },
            {
              img: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/compony_1_5.png',
              title: '业务支持',
              text: '玉螺互联网医疗为合伙人顺利进行业务开展提供便利条件，协助合伙人维护地方政府关系和市场公共关系。',
            },
          ]
        },
        midItem_3: {
          title: '合作事项',
          list: [
            {
              img: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/compony_1_3.png',
              title: '资源共享',
              text: '合伙人提供其市场、人才等资源优势，负责当地相关医疗机构的合作洽谈工作，成为市场拓展负责人，积极拓展当地市场。',
              num: 1,
            },
            {
              img: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/compony_1_4.png',
              title: '品牌授权',
              text: '合伙人需充分认可玉螺互联网医疗的企业文化，代表玉螺互联网医疗在当地组织相关线下交流活动，充分利用各种传播媒介，做好玉螺互联网医疗相关产品推广与宣传工作，提高玉螺互联网医疗品牌知名度。',
              num: 2,
            },
            {
              img: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/compony_1_5.png',
              title: '业务支持',
              text: '定期向玉螺互联网医疗进行业务拓展实施情况汇报，按要求参加玉螺互联网医疗组织的合伙人会议等。',
              num: 3,
            },
          ]
        },
        joinInfo: {
          title: '加入我们',
          hint: '如果您对玉螺互联网医疗「城市合伙人招募计划」感兴趣 \n' +
            '立即申请成为合伙人吧！',
        },
        maskList: [
          {
            info_1: '电话',
            info_2: '0898-6675 5633'
          },
          {
            info_1: '手机',
            info_2: '189 7600 6588'
          },
          {
            info_1: '邮箱',
            info_2: 'marketing@yuluoo.com'
          },
          {
            info_1: '地址',
            info_2: '海南数据谷二号营地2层219-221室'
          },
        ],
        showInfo: false,
      }
    },
    methods: {
      toJoin(){
        // this.$router.push({ path: '/partner_join' })
        this.showInfo = true
      }
    }
  };
</script>

<style lang="stylus" scoped>
  .container{
    display flex
    flex-flow column
    background #F5F5F7
    .top{
      display flex
      flex-flow column
      align-items center
      justify-content center
      width 100%
      height 800px
      background url("https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/compony_banner_3.png") no-repeat
      background-size 100% 100%
      .top_title{
        display flex
        font-size 96px
        color #343A40
        .top_title_small{
        }
        .top_title_big{
          font-weight 900
        }
      }
      .top_hint{
        display flex
        font-size 40px
        color #343A40
        margin 47px 0 0 12px
        .top_hint_small{
        }
        .marginLeft{
          text-indent 1em
        }
        .top_hint_big{
        }
      }
      .top_btn{
        width 288px
        height 75px
        line-height 75px
        border-radius 8px
        background #007BFF
        color #FFF
        font-size 32px
        text-align center
        margin-top 104px
        cursor pointer
      }
    }
    .mid{
      display flex
      flex-flow column
      align-items center
      .mid_hint{
        font-size 20px
        display flex
        flex-flow column
        color #6C757D
        width 1087px
        text-align center
        margin 60px 0
        .mid_hint_title{
          margin-top 28px
        }

      }
      .mid_box{
        display flex
        flex-flow column
        align-items center
        justify-content center
        height 924px
        width 100%
        box-sizing border-box
        padding 0 140px
        .mid_box_title{
          font-size 56px
          font-weight bold
          text-align center
        }
        .box_items{
          display flex
          justify-content space-around
          width 100%
          box-sizing border-box
          padding 0 122px
          margin-top 154px
          .mid_box_item{
            display flex
            flex-flow column
            align-items center
            .item_pic{
              width 140px
              height 140px
            }
            .item_title {
              font-size 40px
              font-weight 500
              color #343A40
              margin-top 40px
            }
            .item_text{
              margin-top 79px
              font-size 20px
              color #6C757D
              line-height 26px
              width 360px
              text-align center
            }
          }
        }

        .box_items_2{
          padding 0
          .mid_box_item{
            background #fff
            width 536px
            height 446px
            display flex
            justify-content center
            align-items center
            .item_text{
              margin-top 23px
              width 456px
            }
          }
          .mid_box_item_3{
            height 320px
            position relative
            .item_text{
              color #6C757D
              line-height 26px
              text-align unset
            }
            .num{
              font-size 200px
              opacity .3
              position absolute
              right 39px
              color #007BFF
              font-weight bold
            }
          }
        }
        .join_box{
          margin-top 104px
          display flex
          flex-flow column
          align-items center
          justify-content center
          background #fff
          width 100%
          height 397px
          .join_box_t{
            width 708px
            font-size 28px
            text-align center
          }
          .btn{
            width 288px
            height 75px
            line-height 75px
            text-align center
            background #007BFF
            border-radius 8px
            color #fff
            font-size 32px
            margin-top 85px
            cursor pointer
          }
        }
      }
    }
    .mask {
      position fixed
      top 0
      right 0
      bottom 0
      left 0
      display flex
      flex-flow column
      align-items center
      justify-content center
      background rgba(255, 255, 255, .6)

      .mask_info {
        width 900px
        background #fff
        border-radius 16px
        display flex
        flex-flow column
        box-sizing border-box
        padding 0 64px
        border: 1px solid #007bff
        box-shadow 32px 32px 10px #888888
        .mask_info_title{
          font-size 38px
          text-align center
          margin-bottom 20px
          margin-top 50px
        }
        .mask_info_item {
          display flex
          font-size 32px
          margin-top 24px
          .right {
            margin-left 18px
          }
        }
        .mask_info_item:first-child{
          margin-top 0
        }
        .mask_btn{
          width 100%
          display flex
          align-items center
          justify-content center
          margin-top 18px
          .btn{
            cursor pointer
            text-align center
            width 280px
            height 60px
            line-height 60px
            border-radius 8px
            border 1px solid #000
            margin 38px 0 68px 0
          }
        }
      }
    }
  }
</style>
